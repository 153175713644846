import '../assets/css/Section1.css'

function Section1({ content, list }) {

    
    return (

        <div className='container mt-4'>
            


            <div className="row align-items-md-stretch mt-4 mb-4">

                <div className='col-lg-12'>
                    <h4 className='text-dark tittle'>¿Por qué elegirnos?</h4>
                    <div className='line-primary'>

                    </div>
                </div>

                {
                    list.length > 0 ?
                        list.map((item) => {
                            return (
                                <div className="col-lg-4">
                                    <div className="h-100 p-5 bg-light rounded-3 border-left-info">
                                        <h2 className='text-muted'>{item.tittle}</h2>
                                        <p>
                                            {item.content}
                                        </p>

                                    </div>
                                </div>
                            );

                        })
                        :
                        <></>
                }

            </div>

        </div>


    );
}

export default Section1;