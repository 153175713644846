import * as React from 'react';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import '../assets/css/Loader.css'


function Loader() {

    return (
        <div className='section-loader bg-info-keyma'>
            <div className='container-fluid'>
                <div className='row h-100 justify-content-center'>
                    <div className='col-lg-12 d-flex justify-content-center'>
                        <Stack>
                            <CircularProgress color="success" />
                        </Stack>
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default Loader;