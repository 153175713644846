import LogoFondo from "../assets/img/logoT.png"

function Logo({len,name}){

    
    return(        
        <div>
            {
                !len ?
                <img className={name} style={{height: '50px'}} src={LogoFondo}></img>
                :len == 'large'?
                <img className={name} style={{height: '150px'}} src={LogoFondo}></img>
                :
                <img className={name} style={{height: '100px'}} src={LogoFondo}></img>
            }
        </div>
    )
}


export default Logo;