import '../assets/css/SectionHead.css'
import FondoInge from "../assets/img/fondo2.jpg"
import Logo from '../shared-components/Logo';

function Head({tittle,img,subtittle,}) {

    const logo = <Logo name="logokey" len="large"></Logo>

    return (
        <section className="py-5 text-center container-fluid" style={{backgroundImage: `url(${FondoInge})`}} id='sectionHead'>
            <div className="row py-lg-5">
                <div className="col-lg-6 col-md-8 mx-auto">
                    {logo}
                    
                    <p className="lead color-primary-keyma fw-bold">
                        {subtittle}
                    </p>
                    

                </div>
            </div>
        </section>
    );
}

export default Head;