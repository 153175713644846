import '../assets/css/AboutUs.css'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import TimelineIcon from '@mui/icons-material/Timeline';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import Logo from '../shared-components/Logo';

function About(){

    const logo = <Logo len={true}></Logo>

    return(
        <div className="" id="about">
            <div className="container d-flex">
                <div className="row d-flex align-items-center justify-content-between">
                    <div className="col-lg-4 mt-5">
                        {logo}
                        <small className='text-text-muted'>¿Quienes somos?</small>
                        <h6 className='text-dark'>Somos una empresa Familiar que presta servicios de contabilidad, recursos humanos, y asesoramiento fiscal. </h6>
                        <p className='small'>Somos el mejor aliado para toda aquella persona física o jurídica que se encuentra inscrita en el misterio 
                            de Hacienda, prestamos servicios tanto a las Micro, pequeñas y medianas 
                            empresas, comerciales y de servicio que funcionan en diferentes sectores del mercado. </p>
                    </div>

                    <div className='col-lg-6'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <div className='card border-0 text-center'>                                        
                                            <div class="card-header">
                                                <AutoAwesomeIcon color='primary' fontSize='large'></AutoAwesomeIcon>
                                            	<h6 className='mt-2'>Transparencia</h6>
                                            </div>
                                            <div class="card-body">                                                
                                                <p class="card-text small">Logramos un buen desempeño laboral a través de nuestro compromiso de trabajar con 
                                                integridad y transparencia para garantizar la justicia, mejorar la confianza, 
                                                la credibilidad, la responsabilidad y tomar decisiones transparentes con la información financiera de la empresa.</p>
                                            </div>                                                                                    
                                    </div>
                                </div>

                                <div className='col-lg-6'>
                                    <div className='card border-0 text-center'>                                        
                                            <div class="card-header">
                                                <TimelineIcon color='primary' fontSize='large'></TimelineIcon>
                                            	<h6 className='mt-2'>Competitividad</h6>
                                            </div>
                                            <div class="card-body">                                                
                                                <p class="card-text small">Seguimos mejorando el desempeño de nuestro estudio y 
                                                estamos interesados en la excelencia y anticiparnos 
                                                al futuro para lograr el primer lugar en todas las áreas de nuestro trabajo</p>
                                            </div>                                                                                    
                                    </div>
                                </div>

                                <div className='col-lg-6'>
                                    <div className='card border-0 text-center'>                                        
                                            <div class="card-header">
                                                <AllInclusiveIcon color='primary' fontSize='large'></AllInclusiveIcon>
                                            	<h6 className='mt-2'>Innovación</h6>
                                            </div>
                                            <div class="card-body">                                                
                                                <p class="card-text small">Buscamos transformar la innovación en trabajo de las empresas mediante el establecimiento 
                                                de un entorno que estimule el pensamiento creativo y la construcción de personas con altas habilidades de innovación.</p>
                                            </div>                                                                                    
                                    </div>
                                </div>

                                <div className='col-lg-6'>
                                    <div className='card border-0 text-center'>                                        
                                            <div class="card-header">
                                                <VolunteerActivismIcon color='primary' fontSize='large'></VolunteerActivismIcon>
                                            	<h6 className='mt-2'>Confianza</h6>
                                            </div>
                                            <div class="card-body">                                                
                                                <p class="card-text small">Creemos en nuestra capacidad como personas, como empresa y como país</p>
                                            </div>                                                                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    
                </div>
            </div>
        </div>
    );
}

export default About;