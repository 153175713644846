import '../assets/css/SectionServices.css'
import { Button } from '@mui/material'
import DoneAllIcon from '@mui/icons-material/DoneAll';
import AdjustIcon from '@mui/icons-material/Adjust';


function Services({ list, showAll = false }) {

    return (
        <div id='services' className="py-5 bg-light">
            <div className="container">

                <div className='row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3'>
                    <div className='col-lg-12'>
                        <h4 className='text-dark tittle'>Nuestros servicios</h4>
                        <div className='line-primary'></div>
                    </div>
                </div>

                <div className="row album row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">

                    {
                        list.length > 0 ?

                            list.map((item) => {
                                return (
                                    <div className="col-lg-3">
                                        <div className="card d-flex flex-row border-0 shadow-sm">

                                            <div className='p-2'>
                                                <AdjustIcon className='mt-2' color='primary' />
                                            </div>

                                            <div className="card-body ">
                                                <h6>
                                                    {item.name}
                                                </h6>

                                                <p className="card-text small text-muted">
                                                    {item.content}
                                                </p>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div className="btn-group">

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                            :
                            <></>
                    }




                </div>
            </div>
        </div>
    );
}

export default Services;