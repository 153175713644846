import * as React from 'react';
import Navbar from './components/Navbar';
import SectionHead from './components/SectionHead';
import Section1 from './components/Section1';
import SectionServices from './components/SectionServices';
import Contact from './components/Contact';
import Footer from './components/Footer';
import WhatsApp from './components/WhatsApp';
import Loader from './components/Loader';
import AboutUs from './components/AboutUs';

function App() {

  const [dataInfo, setDataInfo] = React.useState([{}])  

  const [dataServices, setDataServices] = React.useState([{}]);

  const [loading, setLoading] = React.useState(false)

  

  const getDataInfo=()=>{
    fetch('db/info.json'
    ,{
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
    }
    )
      .then(function(response){        
        return response.json();
      })
      .then(function(myJson) {
        console.log(myJson);
        setDataInfo(myJson);
      });
  }

  const getDataServices=()=>{
    fetch('db/services.json'
    ,{
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
    }
    )
      .then(function(response){        
        return response.json();
      })
      .then(function(myJson) {
        console.log(myJson);
        setDataServices(myJson);
      });
  }
  React.useEffect(()=>{
    getDataInfo();
    getDataServices();
  },[])

  React.useEffect(() => {
    
    setTimeout(() => {
      setLoading(true);  
    }, 1000);
  }, [dataInfo,dataServices])

  const navbar = <Navbar contact={dataInfo[0].contacto} />;
  const sectionHead = <SectionHead tittle={dataInfo[0].tittle} subtittle={dataInfo[0].subtittle} />;
  const section1 = <Section1 content={dataInfo[0].descripcion} list={dataInfo[0].info} />;
  const sectionServices = <SectionServices list={dataServices} />;
  const contact = <Contact object={dataInfo[0].contacto} />;
  const footer = <Footer name="Hola" />;
  const loader = <Loader></Loader>;
  const about = <AboutUs></AboutUs>;


  return (
    <div className="App">

      {
        loading ?
          <React.Fragment>
            {WhatsApp}
            {navbar}
            {sectionHead}
            {sectionServices}
            {section1}            
            {about}
            
            <div className='line-end-primary'>

            </div>
            {footer}
            <div className='line-end-primary'>

            </div>
            
          </React.Fragment>

          :
          <React.Fragment>
            {loader}
          </React.Fragment>
      }


    </div>
  );
}

export default App;
