import React from "react";
import { FloatingWhatsApp } from "react-floating-whatsapp";

/**
 * Repo: https://github.com/awran5/react-floating-whatsapp
 */

export default function WhatsApp() {
  return (
    
      <FloatingWhatsApp
        phoneNumber="123456789"
        accountName="Foo"
        allowEsc
        allowClickAway
        notification
        notificationSound
      />
    
  );
}
