import Logo from "../shared-components/Logo";

function Footer() {

    const logo = <Logo len={false}></Logo>

    return (
        <div className="container ">
            <footer className="py-5">
                <div className="row">
                    
                    <div className="col-4 col-md-2 mb-3">
                        <div className="bg-light">
                            <a className="navbar-brand text-dark" href="#">{logo}</a>
                        </div>
                        <ul className="nav flex-column mt-4">
                            <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Inicio</a></li>
                            <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Servicios</a></li>
                            <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Sobre nosotros</a></li>
                            <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Acerca de</a></li>
                        </ul>
                    </div>

                    <div className="col-md-8 offset-md-1 mb-3">
                        <form>
                            <h5>¿Te contactamos?</h5>
                            <p>Nos pondremos en contacto con usted, brindando información sobre nuestros servicios ¡Gracias!</p>
                            <div className="d-flex flex-column flex-sm-row w-100 gap-2">
                                <label className="visually-hidden">Correo electrónico</label>
                                <input type="text" className="form-control" placeholder="Correo electrónico" />
                                <button className="btn btn-primary" type="button">Enviar</button>
                            </div>
                        </form>
                    </div>
                </div>

                <div className="d-flex flex-column flex-sm-row justify-content-between py-4 my-4 border-top">
                    <p>© 2024 KEYMACR. Reservados todos los derechos.</p>
                    <ul className="list-unstyled d-flex">
                        <li className="ms-3"><a className="link-dark" href="#"></a></li>
                        <li className="ms-3"><a className="link-dark" href="#"></a></li>
                        <li className="ms-3"><a className="link-dark" href="#"></a></li>
                    </ul>
                </div>
            </footer>
        </div>
    );
}

export default Footer;