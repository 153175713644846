import '../assets/css/Navbar.css'
import Logo from '../shared-components/Logo';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import EmailIcon from '@mui/icons-material/Email';

function Navbar({contact}) {

    const logo = <Logo len={false}></Logo>
    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light" id='navbar'>
            <div className="container-fluid">
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="bg-light">
                    <a className="navbar-brand" href="#">
                        {logo}
                    </a>
                </div>
                <div className="collapse navbar-collapse" id="navbarTogglerDemo01">


                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <a className="nav-link active" aria-current="page" href="#">
                                <EmailIcon color='info'></EmailIcon>
                                {contact.correo}</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link active text-muted" aria-current="page" href="#">
                                <PhoneAndroidIcon color='info'></PhoneAndroidIcon>
                                {contact.telefono}</a>
                        </li>

                    </ul>

                </div>
            </div>
        </nav>
    );
}

export default Navbar;